// @ts-nocheck

import React, { useContext, useEffect, useState } from 'react';
import numeral from 'numeral';
import cx from 'classnames';

import { fetchAdvanceInquiry, submitAdvanceInquiryForReview, submitAdvanceInquiryFunding } from '../../../../actions/aloaded';
import Text from '../../../Text';
import i18n from '../../../../i18n';
import { SubmitButton } from '../../../ui/SubmitButton';
import * as styles from './index.module.scss';
import LoadingPage from '../../../ui/LoadingPage';
import Loader from '../../../ui/Loader';
import { ButtonGroup } from '../../../ui/TabBar';
import { ButtonGroupButton } from '../../../ui/ButtonGroup/ButtonGroupButton';
import { ArrowDown, ArrowUp } from 'phosphor-react';
import { useApp, useSystemMode } from '@Src/context/AppContext';
import { navigate } from 'gatsby';
interface Props {
  locale: string
  advanceInquiryId: string
  theme: string
}


const AdvanceInquiryFundingView = ({
  locale = 'en-US',
  theme = null,
  advanceInquiryId
}: Props) => {
  const language = locale.split(/-/g)[0]

  const { isMobile } = useApp()

  const [advanceInquiry, setAdvanceInquiry] = useState<any>(null)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [isError, setError] = useState<boolean>(false)
  const [submit, setSubmit] = useState<boolean>(false)
  const [contractLengthInYears, setContractLengthInYears] = useState(3)
  const [isCalculating, setCalculating] = useState(false)
  const [interestedInBuyout, setInterestedInBuyout] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    fetchAdvanceInquiry(advanceInquiryId).then((advanceInquiryResult) => {
      setAdvanceInquiry(advanceInquiryResult)
      setContractLengthInYears(advanceInquiryResult?.contractLengthInYears)
      setInterestedInBuyout(advanceInquiryResult?.interestedInBuyout)
      setLoading(false)
    }).catch(e => {
      console.error(e)
      setLoading(false)
      setError(true)
    })

  }, [])

  const handleSubmit = (event: any) => {
    event.preventDefault()
    setSubmit(true)
    if (isMobile) {
      submitAdvanceInquiryForReview(advanceInquiryId, {
        ableToMoveCatalogue: true,
        contractLengthInYears,
        files: [],
        interestedInBuyout,
        isMobile,
        distributorName: ''
      }).then(result => {
        if (typeof window !== 'undefined' && window.dataLayer instanceof Array) {
          window.dataLayer.push({
            type: 'event',
            event: 'step_3_submit_advance_inquiry_funding',
            interestedInBuyout,
            ecommerce: {
              transaction_id: advanceInquiryId
            },
            eventCallback: () => {
              navigate(`/advance/inquiry/submitted?advanceInquiryId=${advanceInquiryId}`)
            },
            eventTimeout: 200
          })
        }
      }).catch(e => {
        console.error(e)
        setSubmit(false)
        setError(true)
      })
    } else {
      submitAdvanceInquiryFunding(
        advanceInquiryId,
        {
          contractLengthInYears,
          interestedInBuyout
        }
      ).then((result) => {
        if (typeof window !== 'undefined' && window.dataLayer instanceof Array) {
          window.dataLayer.push({
            type: 'event',
            event: 'step_3_submit_advance_inquiry_funding',
            contractLengthInYears,
            interestedInBuyout,
            ecommerce: {
              transaction_id: advanceInquiryId
            },
            eventCallback: () => {
              navigate(`/advance/inquiry/statements?advanceInquiryId=${advanceInquiryId}`)
            }
          })
        }
      }).catch(err => {
        setSubmit(false)
      })
    }
    return false
  }

  if (advanceInquiry) {
    return (
      /* @ts-ignore */
      <form className={cx(styles.advanceFundingView, theme === 'night' && styles.advanceFundingViewDark)} style={{ opacity: submit ? 0.5 : 1, padding: 0 }} method="POST" onSubmit={handleSubmit}>
        <div style={{ padding: 0, flex: 1 }}>
          <div style={{ margin: 20 }}>
            <Text theme={theme} heading01={i18n.trans(language, interestedInBuyout ? 'you-are-eligible-for-a-buyout-of' : 'you-are-eligible-for-an-advance-of', `€${(interestedInBuyout ? (advanceInquiry?.buyout ?? 0) : (advanceInquiry?.advanceByYears[contractLengthInYears] ?? 0)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0, currency: 'EUR' })}`)} />
          </div>
          <div style={{ margin: 20 }}>
            <Text theme={theme} htmlText={`Choose funding option below and submit. We will be in touch as soon as possible.`} />
          </div> <ButtonGroup theme={theme}>
            <ButtonGroupButton
              theme={theme}
              active={!interestedInBuyout && contractLengthInYears === 3}
              onClick={() => {
                setContractLengthInYears(3)
                setInterestedInBuyout(false)
              }}
            >
              {i18n.trans(language, 'x-year-distribution-deal', 3)}
            </ButtonGroupButton>
            <ButtonGroupButton
              theme={theme}
              active={!interestedInBuyout && contractLengthInYears === 5}
              onClick={() => {
                setContractLengthInYears(5)
                setInterestedInBuyout(false)
              }}
            >
              {i18n.trans(language, 'x-year-distribution-deal', 5)}
            </ButtonGroupButton>
            <ButtonGroupButton
              theme={theme}
              active={interestedInBuyout}
              onClick={() => setInterestedInBuyout(true)}
            >
              {i18n.trans(language, 'full-buyout')}
            </ButtonGroupButton>
          </ButtonGroup>
          <br />
          <SubmitButton
            primary={true}
            isSubmitting={submit}
            theme={theme}
            label={i18n.trans(language, submit ? '' : isMobile ? 'submit' : 'continue-to-upload-reports')}
          />
          <div className={styles.advanceInquiryLayoutMobileGutter} />
        </div>
      </form>
    )
  } else {
    return (
      <div>
        <Text theme={theme} htmlText={i18n.trans(language, 'error')} />
      </div>
    )
  }
}

export default AdvanceInquiryFundingView