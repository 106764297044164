import React from 'react';

import Stepper from '@Src/components/ui/Stepper';
import i18n from '@Src/i18n';
import * as styles from './index.module.scss';
import { useApp } from '@Src/context/AppContext';

interface Props {
  transitionState: string
  locale: string
  currentStepId: string
  pageType: string
  advanceInquiryId: string
  children: any
}

const AdvanceInquiryLayout = ({ 
  transitionState,
  currentStepId = '',
  pageType = '',
  advanceInquiryId = '',
  locale = 'en-US',
  children,
  ...props
}: Props) => {
  const { mode } = useApp()
  const language = locale.split(/-/)[0]
  console.log("transitionState", transitionState)
  return (  
    <div className={styles.AdvanceInquiryLayout}>
      <div className={styles.advanceInquiryLayoutGrid}>
        <Stepper
          logotype={
            <img className={styles.logotype} src="/assets/svg/ALOADED-LOGOTYPE.svg" style={{width: 'auto'}} />
          }
          orientation="horizontal"
          // @ts-ignore
          theme={mode}
          maxStep={advanceInquiryId?.length > 0 ? 5 : 1}
          currentStepId={currentStepId}
          baseUrl={`/advance/inquiry`}
          steps={[
            {
              name: i18n.trans(language, 'funding-options'),
              url: `/funding?advanceInquiryId=${advanceInquiryId}`,
              id: 'funding'
            },
            {
              name: i18n.trans(language, 'distribution-reports'),
              url: `/statements?advanceInquiryId=${advanceInquiryId}`,
              id: 'statements'
            }
          ]}
        />
        <div className={styles.advanceInquiryLayoutStep}>
          {children}
        </div>
      </div>
    </div> 
  )
} 

export default AdvanceInquiryLayout