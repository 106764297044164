// extracted by mini-css-extract-plugin
export var advanceFundingView = "index-module--advanceFundingView--e0f15";
export var advanceInquiryLayoutMobileGutter = "index-module--advanceInquiryLayoutMobileGutter--681ec";
export var advanceInquiryLayoutSummary = "index-module--advanceInquiryLayoutSummary--c5de9";
export var advanceInquiryLayoutSummaryExpanded = "index-module--advanceInquiryLayoutSummaryExpanded--084ea";
export var advanceInquirySubmitDesktop = "index-module--advanceInquirySubmitDesktop--90958";
export var advanceInquirySubmitMobile = "index-module--advanceInquirySubmitMobile--65d23";
export var advanceInquirySummaryDetailed = "index-module--advanceInquirySummaryDetailed--9916e";
export var advanceInquirySummaryDrawerHandle = "index-module--advanceInquirySummaryDrawerHandle--ba7f4";
export var advanceInquirySummarySimple = "index-module--advanceInquirySummarySimple--5fa2f";
export var advanceInquirySummaryTable = "index-module--advanceInquirySummaryTable--29e6d";
export var advanceInquirySummaryTableDark = "index-module--advanceInquirySummaryTableDark--8edde";
export var amount = "index-module--amount--ed076";
export var royaltyPercents = "index-module--royaltyPercents--4c9f2";