// @ts-nocheck

import React from 'react';

import cx from 'classnames';

import * as styles from 
 './index.module.scss';


interface ButtonGroupProps {
    children: any
    theme: string
}

export const ButtonGroup = ({
    children,
    theme
}: ButtonGroupProps) => {
    return (
        <div className={cx(styles.buttonGroup, theme === 'dark' || theme === 'night' && styles.buttonGroupDark)}>
          {children}
        </div>
    )
}