import React, { ReactElement, useEffect, useState } from 'react';
import { IPageData, IPageProps } from '@Types/ContentfulTypes';
import { usePageTheme, useApp, useSystemMode } from '@Context/AppContext';
import '@GraphQL/fragments';
import AdvanceInquiryFundingView from '@Src/components/Advance/Inquiry/Funding';
import AdvanceInquiryLayout from '@Src/components/Advance/Inquiry/Layout';
import i18n from '@Src/i18n';
import { submitAdvanceInquiryFunding } from '@Src/actions/aloaded';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';

export interface Props extends IPageProps {
  data: {
    contentfulPageTypePitch: IPageData;
  };
  locale?: string
  path?: string
}

const AdvanceInquiryFundingPage = ({ data, location, pageContext, transitionStatus }: Props): ReactElement => {
  //const { title, seoSettings, content } = data.contentfulPageTypePitch;
  const title = '', seoSettings = 'label pitch'
  let locale = 'en-US'
  const { setTheme: setPageTheme } = usePageTheme();
  const { mode, pageType, setPageType, setPageTitle } = useApp();
  const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);
  const [content, setContent] = useState(null)
  const language = locale.split(/-/)[0]
  const [advanceInquiryId, setAdvanceInquiryId] = useState<string | null | undefined>(null)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const query = new URLSearchParams(window.location.search)
      setAdvanceInquiryId(query.get('advanceInquiryId')!)
    }
  })
  useEffect(() => {
    if (pageType === 'index' && transitionStatus == 'exiting') {
      setLocalTransStatus(transitionStatus + 'Index');
    } else {
      setLocalTransStatus(transitionStatus);
    }

    if (transitionStatus == 'entering' || transitionStatus == 'entered') {
      setPageTheme(mode == 'night' ? 'dark' : 'light');
      setPageTitle(title);
      setPageType('advance');
    }
  }, [transitionStatus]);
  useEffect(() => {
    if (transitionStatus == 'entered') {
      setPageTheme(mode == 'night' ? 'dark' : 'light');
    }
  }, [mode]);

  return (
    <ContentLayout type={pageType} transitionState={localTransStatus} fullWidth>
      <div style={{ padding: 0 }}>
        {advanceInquiryId && <AdvanceInquiryLayout
          pageType={pageType}
          transitionState={localTransStatus}
          currentStepId={"funding"}
          locale={locale}
          advanceInquiryId={advanceInquiryId}
        >
          <AdvanceInquiryFundingView
            advanceInquiryId={advanceInquiryId}
            locale={locale}
            theme={mode}
          />
        </AdvanceInquiryLayout>}
      </div>
    </ContentLayout>
  );
};

export default AdvanceInquiryFundingPage;